<template>
  <v-container class="credits-history pa-0">
    <v-row class="ma-auto title-header">
      <v-col class="d-flex align-center py-0 pl-0" cols="10">
        <span class="text modal-header-title"> Credit History </span>
      </v-col>
      <v-col class="d-flex justify-end py-0 pr-0">
        <v-icon color="darken-1" @click="$emit('close-modal')">mdi-close</v-icon>
      </v-col>
    </v-row>
    <v-row class="ma-auto" v-if="creditsHistory.length">
      <v-col class="pa-5">
          <v-row class="ma-auto table-header">
            <v-col cols="2" class="label">Date</v-col>
            <v-col cols="7" class="label">Reason</v-col>
            <v-col class="text-center label">Credits</v-col>
          </v-row>
          <div class="table-data scrollable">
            <v-row class="ma-auto data-row cursor-pointer"
              v-for="item in creditsHistory" :key="item.id">
              <v-col cols="2" class="data text-wrap">
                {{ date(item.createdAt) }}
              </v-col>
              <v-col cols="7" class="data">
                <div v-if="item.description !== 'Order created'
                        && item.description !== 'Order deleted' &&
                        item.description !== 'Order resubmitted'">{{ reason(item.reasonId) }}</div>
                  <span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <div v-if="item.description === 'Order created'
                        || item.description === 'Order deleted' ||
                        item.description === 'Order resubmitted'"
                        class="cursor-pointer" v-on="on">
                          {{ item.description }}
                        </div>
                        <div v-else
                        class="notes cursor-pointer text-wrap" v-on="on">
                          {{ item.description }}
                        </div>
                      </template>
                      <span>
                        <div
                        style="max-width: 200px;">
                        {{ item.description }}</div>
                      </span>
                    </v-tooltip>
                  </span>
              </v-col>
              <v-col
                v-if="item.operationType === '1'"
                class="data text-center">+{{ item.credits }}</v-col>
              <v-col v-else class="data text-center">-{{ item.credits }}</v-col>
            </v-row>
          </div>
        </v-col>
    </v-row>
        <v-row class="ma-auto no-history" v-else>
            <v-col class="pb-5">
                <div>No history</div>
            </v-col>
        </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'history',
  computed: {
    ...mapGetters('business', ['creditsHistory', 'reasons']),
  },
  methods: {
    reason(id) {
      const reasonObj = _.find(this.reasons, (item) => item.id === id);
      return _.get(reasonObj, 'reason', '');
    },
    date(createdDate) {
      return moment(createdDate).format('M/DD/YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
.credits-history {
  min-height: 250px;
  .title-header {
    border-bottom: 1px solid #e0e0e0;
    padding: 16px 21px 13px 21px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #929292;
  }
  .scrollable {
    overflow: auto;
    max-height: 450px;
    padding-top: 18px;
    padding-bottom: 4px;
  }
  .table-header {
    border-bottom: 1px solid #e0e0e0;
    .label {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #262626;
      padding: 0px;
      margin-right: 5px;
      margin-bottom: 10px !important;
      &:last-child {
        margin-right: 5px;
      }
    }
  }
  .table-data {
    border-bottom: 1px solid #e0e0e0;
    .data-row {
      margin-bottom: 15px !important;
    }
    .data {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #262626;
      padding: 0px;
      margin-right: 10px;
    }
    .notes {
      width: fit-content;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #929292;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .no-history {
    text-align: center;
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    color: $charcoalBlack;
    height: 74%;
    display: flex;
    align-items: center;
  }
}
.text-wrap {
  overflow-wrap: break-word;
}
</style>
