<!-- eslint-disable max-len -->
<template>
  <div class="paymentmethod-container">
    <v-row class="ma-auto model-header">
      <v-col class="d-flex align-center py-0 pl-0" cols="10">
        <span class="text modal-header-title">
          {{ cardDetails.paymentMethodId ? 'Update Payment Method' : 'Add Payment Method' }}
        </span>
      </v-col>
      <v-col class="d-flex justify-end py-0 pr-0">
        <v-icon color="darken-1" @click="$emit('closemodal')">mdi-close</v-icon>
      </v-col>
    </v-row>
    <div
      :style="{
        'padding': !cardDetails.paymentMethodId ? '20px': '15px'
      }"
      class="media-type-container pb-0">
      <div class="media-type">card details</div>
      <div class="long-line"></div>
    </div>
    <div class="carddetails-container" :style="{
      'padding': !cardDetails.paymentMethodId ? '20px' : '0px',
      'padding-top': !cardDetails.paymentMethodId ? '5px' : '0px'
    }">
      <div v-if="!cardDetails.paymentMethodId">
    <v-row
      :style="{
        'border': numberErr.length > 0 ? '1px solid red' : '1px solid #dedede'
      }"
      class="cardnumber-div ma-0" style="padding-top: 6px;padding-bottom: 4px;">
      <v-col
        cols="5"
        style="padding-top: 5px;padding-bottom: 3px;"
        id="card-number" ref="cardnumber"></v-col>
          <v-col
            :style="{
              'padding-bottom' : cardCom ? '5px' : '0px',
            }"
            style="padding-top: 1px;padding-right: 10px;"
            class="pl-0 d-flex justify-end">
            <div v-if="!cardCom">
              <img
                class="image"
                :src="require(`@/assets/cardIcons/visa.svg`)"
                width="40"/>
              <img
                v-if="$vuetify.breakpoint.lgAndUp"
                class="image image2"
                :src="require(`@/assets/cardIcons/mastercard.svg`)"
                width="40"/>
              <img
                class="image"
                :src="require(`@/assets/cardIcons/discover.svg`)"
                width="40"/></div>
              <img
                :style="{
                  'padding-bottom' : !cardCom ? '5px' : '0px',
                }"
                class="image"
                width="40"
                :src="require(`@/assets/cardIcons/${cardType}.svg`)"/>
            </v-col>
    </v-row>
    <span class="error-message" v-if="numberErr.length > 0">{{ numberErr }}</span>
      <v-row class="ma-0">
        <v-col cols="6" class="pl-0">
        <div
          :style="{
            'border': expiryErr.length > 0 ? '1px solid red' : '1px solid #dedede'
          }"
          class="cardnumber-div pa-3" id="card-expiry">
        </div>
        <span class="error-message" v-if="expiryErr.length > 0">{{ expiryErr }}</span>
        </v-col>
        <v-col cols="6" class="pr-0">
        <div
          :style="{
            'border': cvcErr.length > 0 ? '1px solid red' : '1px solid #dedede'
          }"
          class="cardnumber-div pa-3" id="card-cvc"></div>
        <span class="error-message" v-if="cvcErr.length > 0">{{ cvcErr }}</span>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="6" class="pl-0 pt-0">
          <v-select
            solo
            flat
            class="type-selection mb-n3 notranslate"
            placeholder="Country"
            :items="countriesList"
            v-model="country"
            item-text="label"
            item-value="value"
            append-icon="mdi-chevron-down"
          ></v-select>
        </v-col>
        <v-col cols="6" class="pr-0 pt-0">
          <div
            :style="{
              'border': postalCodeErr.length > 0 ? '1px solid red' : '1px solid #dedede'
            }"
            class="cardnumber-div pa-3" id="card-postal"></div>
        <span class="error-message" v-if="postalCodeErr.length > 0">{{ postalCodeErr }}</span>
        </v-col>
      </v-row>
      </div>
      <div v-if="cardDetails.paymentMethodId">
        <v-col class="pb-0">
          <div>
            <v-text-field
            :disabled="cardDetails.paymentMethodId ? true : false"
            class="type-selection payout-input"
            outlined
            dense
            :value="`**** ${cardDetails.last4}`"
            name="payout"
            type="text"
          >
                  <template v-slot:append>
                        <img
                          class="image"
                          width="40"
                          :src="require(`@/assets/cardIcons/${cardDetails.brand}.svg`)"/>
                  </template>
                </v-text-field>
        </div>
        </v-col>
        <v-row class="ma-0">
          <v-col cols="6" xs="5" sm="5" md="6" lg="6" xl="6" xxl="6">
            <div
              :style="{
                'border' : dateError.length > 0 ? '1px solid red' : '1px solid #e6e8ed'
              }" class="input-field">
              <input
                @paste.prevent
                v-model="expDate"
                @blur="checkExpiry"
                type="tel"
                maxlength="5"
                placeholder="Expiration (MM / YY)" />
            </div>
            <p class="pl-2 mb-0" style="color: red;" v-if="dateError">{{ dateError }}</p>
          </v-col>
          <v-col cols="6" xs="5" sm="5" md="6" lg="6" xl="6" xxl="6">
            <div style="border: 1px solid #e6e8ed" class="input-field">
              <input
                :disabled="cardDetails.paymentMethodId ? true : false"
                type="tel"
                maxlength="3"
                placeholder="CVC" />
            </div>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="6" xs="5" sm="5" md="6" lg="6" xl="6" xxl="6">
            <div class="pb-1">
            <v-select
                  solo
                  flat
                  class="type-selection mb-n3 notranslate"
                  placeholder="Country"
                  :items="countriesList"
                  v-model="country"
                  item-text="label"
                  item-value="value"
                  append-icon="mdi-chevron-down"
                ></v-select>
            </div>
          </v-col>
          <v-col cols="6" xs="5" sm="5" md="6" lg="6" xl="6" xxl="6">
            <div :style="{
                'border' : zipcodeError.length > 0 ? '1px solid red' : '1px solid #e6e8ed'
              }" class="input-field pb-1">
              <input
                v-model="zipcode"
                @blur="checkZipcode"
                type="tel"
                maxlength="5"
                placeholder="Zip" />
            </div>
            <p class="pl-1 mb-0" style="color: red;" v-if="zipcodeError">{{ zipcodeError }}</p>
          </v-col>
        </v-row>
  </div>
  </div>
  <v-col class="pt-1 text-center" v-if="errormessage.length > 0" style="color: red;">
    {{ errormessage }}
  </v-col>
  <v-col class="d-flex justify-end pt-0">
    <div class="continue-btn text-center">
        <v-btn
          text
          class="cancel mr-5"
          @click="$emit('closemodal')">Cancel
        </v-btn>
        <v-btn
          class="register-button btn-purple"
          type="submit"
          :loading="loading"
          @click="submitCard"
        >Save
        </v-btn>
      </div>
    </v-col>
  <!-- <div class="paymentmethod-container">
    <v-row v-if="!purchaseModel" class="ma-auto model-header">
        <v-col class="d-flex align-center py-0 pl-0" cols="10">
          <span class="text modal-header-title">
            {{ cardDetails.paymentMethodId ? 'Update Payment Method' : 'Add Payment Method' }}
          </span>
        </v-col>
        <v-col class="d-flex justify-end py-0 pr-0">
          <v-icon color="darken-1" @click="$emit('closemodal')">mdi-close</v-icon>
        </v-col>
      </v-row>
        <span>
        <div class="d-flex justify-center mr-0">
        <v-col class="pr-0 pb-0">
        <span
            class="d-flex no-wrap">
            <span class="creative-heading ml-1">card details</span>
              <p class="line mb-0 mb-3 pt-0 ml-2"></p>
          </span>
              <stripe-element-payment
                ref="paymentRef"
                :pk="pk"
                :elements-options="elementsOptions"
                :confirm-params="confirmParams"
              />
        </v-col>
          </div>
        <div>
        <v-col class="pb-0">
          <div>
            <v-text-field
            :class="{
              'errorState v-input--has-state error--text': numberror.length > 0,
            }"
            :disabled="cardDetails.paymentMethodId && !purchaseModel ? true : false"
            class="type-selection payout-input"
            outlined
            dense
            v-model="cardNumber"
            @input="checkType"
            @blur="checkNumber"
            @paste.prevent
            placeholder="Card Number"
            name="payout"
            type="text"
            maxlength="19"
          >
                  <template v-slot:append>
                      <div v-if="!cardNumber.length">
                        <img
                          class="image"
                          :src="require(`@/assets/cardIcons/visa.svg`)"
                          width="40"/>
                        <img
                          v-if="$vuetify.breakpoint.lgAndUp"
                          class="image image2"
                          :src="require(`@/assets/cardIcons/mastercard.svg`)"
                          width="40"/>
                        <img
                          class="image"
                          :src="require(`@/assets/cardIcons/discover.svg`)"
                          width="40"/></div>
                        <img
                          class="image"
                          width="40"
                          :src="require(`@/assets/cardIcons/${cardType}.svg`)"/>
                  </template>
                </v-text-field>
        </div>
        <p class="pl-2 mb-0" style="color: red;" v-if="numberror">{{ numberror }}</p>
        </v-col>
        <v-row class="ma-0">
          <v-col cols="6" xs="5" sm="5" md="6" lg="6" xl="6" xxl="6">
            <div
              :style="{
                'border' : dateError.length > 0 ? '1px solid red' : '1px solid #e6e8ed'
              }" class="input-field">
              <input
                @paste.prevent
                v-model="expDate"
                @blur="checkExpiry"
                type="tel"
                maxlength="5"
                placeholder="Expiration (MM / YY)" />
            </div>
            <p class="pl-2 mb-0" style="color: red;" v-if="dateError">{{ dateError }}</p>
          </v-col>
          <v-col cols="6" xs="5" sm="5" md="6" lg="6" xl="6" xxl="6">
            <div
                :style="{
                  'border' : cvvError.length > 0 ? '1px solid red' : '1px solid #e6e8ed'
                }" class="input-field">
              <input
                :disabled="cardDetails.paymentMethodId && !purchaseModel ? true : false"
                v-model="cvv"
                @paste.prevent
                @blur="checkCvv"
                type="tel"
                maxlength="3"
                placeholder="CVC" />
            </div>
            <p class="pl-2 mb-0" style="color: red;" v-if="cvvError">{{ cvvError }}</p>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="6" xs="5" sm="5" md="6" lg="6" xl="6" xxl="6">
            <div class="pb-1">
            <v-select
                  solo
                  flat
                  class="type-selection mb-n3 notranslate"
                  placeholder="Country"
                  :items="countriesList"
                  v-model="country"
                  item-text="label"
                  item-value="value"
                  append-icon="mdi-chevron-down"
                ></v-select>
            </div>
          <p class="pl-1 mb-0 pt-1" v-if="countryError" style="color: red;">{{ countryError }}</p>
          </v-col>
          <v-col cols="6" xs="5" sm="5" md="6" lg="6" xl="6" xxl="6">
            <div :style="{
                'border' : zipcodeError.length > 0 ? '1px solid red' : '1px solid #e6e8ed'
              }" class="input-field pb-1">
              <input
                v-model="zipcode"
                @blur="checkZipcode"
                type="tel"
                maxlength="5"
                placeholder="Zip" />
            </div>
            <p class="pl-1 mb-0" style="color: red;" v-if="zipcodeError">{{ zipcodeError }}</p>
          </v-col>
        </v-row>
        </div>
        <v-col class="pt-1 text-center" v-if="errormessage.length > 0" style="color: red;">
          {{ errormessage }}
        </v-col>
          <v-col v-if="!purchaseModel" class="d-flex justify-end">
          <div class="continue-btn text-center">
              <v-btn
                text
                class="cancel mr-5"
                @click="$emit('closemodal')">Cancel
              </v-btn>
              <v-btn
                class="register-button btn-purple"
                type="submit"
                :loading="loading"
                @click="submitCard"
              >Save
              </v-btn>
            </div>
          </v-col>
          <div v-else>
            <v-checkbox
              @change="sendCheck"
              v-model="selected"
              label="Save as primary payment method for future purchase"
              class="mt-3 ml-3 checkbox"
            >
            </v-checkbox>
          </div>
          </span>
  </div> -->
  </div>
</template>

<script>
// import { StripeElementPayment } from '@vue-stripe/vue-stripe';
// import BaseInput from '@/components/common/BaseInput';
import { mapActions } from 'vuex';
import countries from '../../../../plugins/HyperTranslate/countries.json';

const cardValidator = require('card-validator');

export default {
  components: {
    // StripeElementPayment,
    // BaseInput,
  },
  props: {
    userDetails: {
      type: Object,
    },
    cardDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      numberErr: '',
      expiryErr: '',
      cvcErr: '',
      postalCodeErr: '',
      countriesList: [],
      country: 'US',
      cardCom: false,
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      cardType: 'american express',
      numberField: false,
      expiryField: false,
      cvcField: false,
      postalCodeField: false,
      loading: false,
      zipcodeError: '',
      countryError: '',
      dateError: '',
      expDate: '',
      numberror: '',
      zipcode: '',
      cvv: '',
      cvvError: '',
      errormessage: '',
    };
  },
  watch: {
    // eslint-disable-next-line
    // 'cardNumber': function() {
    //   this.cardNumber =
    // this.cardNumber.replace(/[^0-9!@#$%^&*]/g, '').replace(/(.{4})/g, '$1 ').trim();
    // },
    // eslint-disable-next-line
    'expDate': function() {
      this.expDate = this.expDate.replace(/\D/g, '').substring(0, 2) + (this.expDate.length > 2 ? '/' : '') + this.expDate.replace(/\//g, '').substring(2, 4);
    },
    // eslint-disable-next-line
    'zipcode': function() {
      this.zipcode = this.zipcode.replace(/\D/g, '');
    },
    // eslint-disable-next-line
    // 'cvv': function() {
    //   this.cvv = this.cvv.replace(/\D/g, '');
    // },
  },
  computed: {
    stripeElements() {
      return this.$stripe.elements();
    },
    checkForm() {
      if (this.numberErr.length > 0
        || this.cvcErr.length > 0 || this.expiryErr.length > 0 || this.postalCodeErr.length > 0) {
        return false;
      }
      if (this.cardDetails.paymentMethodId) {
        if (this.zipcodeError.length > 0 || this.dateError.length > 0) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    ...mapActions('user', ['addPayment', 'updatePaymentCard']),
    // checkCountry() {
    //   if (this.country.length === 0) {
    //     // eslint-disable-next-line
    //     this.countryError = 'Enter Country';
    //     return this.countryError;
    //   }
    //   // eslint-disable-next-line
    //   this.countryError = '';
    //   return this.countryError;
    // },
    checkZipcode() {
      if (!this.zipcode) {
        // eslint-disable-next-line
        this.zipcodeError = 'Enter Zipcode';
        return this.zipcodeError;
      }
      if (this.zipcode.length < 5) {
        this.zipcodeError = 'Enter 5 digits zipcode';
        return this.zipcodeError;
      }
      // if (this.zipcode.length > 5) {
      //   this.zipcodeError = 'Enter 5 digits zipcode';
      //   return this.zipcodeError;
      // }
      // eslint-disable-next-line
      this.zipcodeError = '';
      return this.zipcodeError;
    },
    getRandomCardType() {
      const cardTypes = ['american express', 'jcb', 'diners', 'elo', 'unionpay'];
      const randomIndex = Math.floor(Math.random() * cardTypes.length);
      return cardTypes[randomIndex];
    },
    // checkCvv() {
    //   const cardCvvValidation = cardValidator.cvv(this.cvv);
    //   if (cardCvvValidation.isValid) {
    //     this.cvvError = '';
    //   } else {
    //     // eslint-disable-next-line
    //     if (cardCvvValidation.isPotentiallyValid && !this.cardDetails.paymentMethodId) {
    //       if (this.cvv.length === 0) {
    //         this.cvvError = 'Your cvc number is incomplete';
    //       } else {
    //         this.cvvError = 'Your cvv is invalid';
    //       }
    //     }
    //   }
    // },
    checkExpiry() {
      const cardDateValidation = cardValidator.expirationDate(this.expDate);
      if (cardDateValidation.isValid) {
        this.dateError = '';
      } else {
        // eslint-disable-next-line
        if (cardDateValidation.isPotentiallyValid) {
          if (this.cvv.length === 0) {
            this.dateError = 'Your expiry date is incomplete';
          } else {
            this.dateError = 'Your card expiry date is invalid';
          }
        }
      }
    },
    // checkType() {
    //   const cardTypeValidation = cardValidator.number(this.cardNumber);
    //   if (cardTypeValidation.isValid) {
    //     this.showIcon = true;
    //   } else {
    //     // eslint-disable-next-line
    //     if (cardTypeValidation.card && cardTypeValidation.card.type) {
    //       this.cardType = cardTypeValidation.card.type;
    //     } else {
    //       this.cardType = 'unknown';
    //     }
    //   }
    // },
    // checkNumber() {
    //   if (!this.cardNumber) {
    //     this.numberror = 'Your card number is incomplete';
    //   }
    //   const cardNumberValidation = cardValidator.number(this.cardNumber);
    //   if (this.cardNumber.length > 15) {
    //     if (cardNumberValidation.isValid) {
    //       this.numberror = '';
    //       // this.showIcon = true;
    //     } else {
    //       // eslint-disable-next-line
    //       if (cardNumberValidation.isValid === false) {
    //         this.numberror = 'Your card number is invalid';
    //       }
    //       // if (cardNumberValidation.card && cardNumberValidation.card.type) {
    //       //   this.cardType = cardNumberValidation.card.type;
    //       //   if (cardNumberValidation.card) {
    //       //     this.numberror = 'Your card number is invalid';
    //       //   }
    //       // } else {
    //       //   this.cardType = 'unknown';
    //       // }
    //     }
    //   }
    // },
    async submitCard() {
      // this.checkCountry();
      // this.checkCvv();
      this.checkExpiry();
      // this.checkNumber();
      this.checkZipcode();
      if (!this.cardDetails.paymentMethodId) {
        this.loading = true;
        const { token, error } = await this.$stripe.createToken(this.cardNumber);
        if (error) {
          this.loading = false;
        }
        const payload = {
          businessId: this.userDetails.currentBusinessId,
          last4: token.card.last4,
          token: token.id,
          expMonth: token.card.exp_month,
          expYear: token.card.exp_year,
          // cvv: this.cvv,
          brand: token.card.brand.toLowerCase(),
          address: {
            country: token.card.country,
            postal_code: token.card.address_zip,
          },
          priority: true,
        };
        if (token.card && this.checkForm) {
          const result = await this.addPayment(payload);
          if (result.success) {
            this.loading = false;
            this.$emit('closemodal');
            this.$emit('reload-cards');
          }
          if (result.data.error) {
            this.loading = false;
            this.errormessage = result.data.error;
          }
        }
      }
      if (this.cardDetails.paymentMethodId && this.checkForm) {
        this.loading = true;
        const updatePayload = {
          address: {
            country: this.country,
            postal_code: Number(this.zipcode),
          },
          expMonth: Number(this.expDate.slice(0, 2)),
          expYear: Number(`20${this.expDate.slice(3, 5)}`),
          brand: this.cardDetails.brand,
          businessId: this.userDetails.currentBusinessId,
          paymentMethodId: this.cardDetails.paymentMethodId,
          priority: true,
        };
        const result = await this.updatePaymentCard(updatePayload);
        if (result.success) {
          this.loading = false;
          this.$emit('closemodal');
          this.$emit('reload-cards');
        }
        if (result.data.error) {
          this.errormessage = result.data.error;
          this.loading = false;
        }
      }
    },
  },
  mounted() {
    this.$router.replace({
      path: '/billing',
      query: {
        method: 'payment',
      },
    });
    if (this.cardDetails.paymentMethodId) {
      this.country = this.cardDetails.country;
      this.zipcode = this.cardDetails.zipcode;
      this.expDate = `${this.cardDetails.expMonth > 9 ? '' : '0'}${this.cardDetails.expMonth}/${String(this.cardDetails.expYear).slice(-2)}`;
    }
    // Style Object documentation here: https://stripe.com/docs/js/appendix/style
    if (!this.cardDetails.paymentMethodId) {
      const style = {
        base: {
          color: 'black',
          fontSize: '16px',
          fontWeight: 400,
          // '::placeholder': {
          //   color: '#aab7c4',
          // },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      };
      this.cardNumber = this.stripeElements.create('cardNumber', {
        style,
        placeholder: 'Card Number',
      });
      this.cardNumber.mount('#card-number');
      this.cardNumber.on('change', (event) => {
        if (event.complete) {
          this.numberField = true;
          this.numberErr = '';
          this.cardCom = event.complete;
          this.cardType = event.brand;
        } else {
          if (event.empty) {
            this.numberErr = 'Your card number is incomplete';
          }
          if (event.error.message) {
            this.numberErr = event.error.message;
          }
          this.cardType = null;
        }
      });
      this.cardExpiry = this.stripeElements.create('cardExpiry', {
        style,
        placeholder: 'Expiration (MM / YY)',
      });
      this.cardExpiry.mount('#card-expiry');
      this.cardExpiry.on('change', (event) => {
        if (event.complete) {
          this.expiryField = true;
          this.expiryErr = '';
        } else {
          if (event.empty) {
            this.expiryErr = 'Your expiry date is incomplete';
          }
          if (event.error.message) {
            this.expiryErr = 'Your card expiry date is invalid';
          }
        }
      });
      this.cardCvc = this.stripeElements.create('cardCvc', { style });
      this.cardCvc.mount('#card-cvc');
      this.cardCvc.on('change', (event) => {
        if (event.complete) {
          this.cvcField = true;
          this.cvcErr = '';
        } else {
          if (event.empty) {
            this.cvcErr = 'Your cvc number is incomplete';
          }
          if (event.error.message) {
            this.cvcErr = 'Your cvv is invalid';
          }
        }
      });
      this.postalCode = this.stripeElements.create('postalCode', {
        style,
        placeholder: 'Zip',
      });
      this.postalCode.mount('#card-postal');
      this.postalCode.on('change', (event) => {
        if (event.complete) {
          if (event.value.length > 7) {
            this.postalCodeErr = 'Your postal code is invalid';
          } else {
            this.postalCodeErr = '';
          }
          this.postalCodeField = true;
        } else {
          this.postalCodeErr = 'Your postal code is incomplete';
        }
      });
      setInterval(() => {
        if (!this.cardCom) {
          this.cardType = this.getRandomCardType();
        }
      }, 3000);
    }
    this.countriesList = countries;
  },
  beforeDestroy() {
    if (!this.cardDetails.paymentMethodId) {
      this.cardNumber.destroy();
      this.cardExpiry.destroy();
      this.cardCvc.destroy();
      this.postalCode.destroy();
    }
  },
};
</script>

<style lang="scss" scoped>
.paymentmethod-container {
  .media-type-container {
    display: flex;
    align-items: center;
    .media-type {
      font-weight: 400;
      color: #bdbdbd;
      font-weight: bold;
      position: relative !important;
      width: fit-content;
      text-transform: uppercase;
    }
    .long-line {
      flex: 1;
      margin-left: 1rem;
      height: 2px;
      background: #e0e0e0;
    }
  }
  .carddetails-container {
  font-family: 'Poppins' !important;
  .error-div {
    border: 1px solid red;
  }
  .checkbox {
    ::v-deep .v-input--selection-controls__input + .v-label {
      color: #262626 !important;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .error-message {
    font-size: 14px;
    color: red;
  }
  .image {
      padding-left: 5px;
    }
    @media (max-width: 600px) {
      .image2 {
        display: none;
      }
    }
  .cardnumber-div {
    background-color: white;
    border: 1px solid #dedede;
    border-radius: 5px;
  }
}
  .checkbox {
    ::v-deep .v-input--selection-controls__input + .v-label {
      color: #262626 !important;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .input-field {
    padding: 5px;
    border-radius: 5px;
    background-color: white;
    ::v-deep input {
      width: 200px;
      font-size: 14px;
      line-height: 20px;
      padding-left: 5px;
      height: 35px;
      border: none !important;
    }
    :focus-visible {
    outline: none !important;
    }
  }
  @media (max-width: 600px) {
    ::v-deep .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
      width: 50px !important;
    }
  }
  .creative-heading {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #999;
  text-transform: uppercase;
    }
    .line {
        width: 70%;
        border-bottom: 2px solid #EBEBEB;
    }
    @media (max-width: 600px) {
      .line {
        width: 55%;
      }
    }
    ::v-deep .type-selection {
    .image {
      padding: 2px;
    }
    .v-input__slot {
      margin-bottom: 0px;
    }
    .v-input__control {
      background-color: white;
    }
    .v-input--dense > .v-input__control > .v-input__slot {
      margin-bottom: 0px;
    }
    .v-text-field__slot {
      max-width: 200px !important;
    }
    @media (max-width: 600px) {
      .image2 {
        display: none;
      }
    }
    .v-text-field__details {
      display: none !important;
    }
    .v-input__slot {
      height: 45px;
      border: solid 1px #e6e8ed;
      .v-select__selection {
        line-height: 21px;
      }
    }
  }
}
</style>
