

<!-- eslint-disable max-len -->
<template>
  <svg :width="width" :height="height" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 10H2.5C1.8372 9.99921 1.20178 9.73556 0.73311 9.26689C0.264441 8.79822 0.000793929 8.1628 0 7.5L0 2.5C0.000793929 1.8372 0.264441 1.20178 0.73311 0.73311C1.20178 0.264441 1.8372 0.000793929 2.5 0L7.5 0C8.1628 0.000793929 8.79822 0.264441 9.26689 0.73311C9.73556 1.20178 9.99921 1.8372 10 2.5V7.5C9.99921 8.1628 9.73556 8.79822 9.26689 9.26689C8.79822 9.73556 8.1628 9.99921 7.5 10ZM2.5 1C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V7.5C1 7.89782 1.15804 8.27936 1.43934 8.56066C1.72064 8.84196 2.10218 9 2.5 9H7.5C7.89782 9 8.27936 8.84196 8.56066 8.56066C8.84196 8.27936 9 7.89782 9 7.5V2.5C9 2.10218 8.84196 1.72064 8.56066 1.43934C8.27936 1.15804 7.89782 1 7.5 1H2.5ZM12 9.5V3C12 2.86739 11.9473 2.74021 11.8536 2.64645C11.7598 2.55268 11.6326 2.5 11.5 2.5C11.3674 2.5 11.2402 2.55268 11.1464 2.64645C11.0527 2.74021 11 2.86739 11 3V9.5C11 9.89782 10.842 10.2794 10.5607 10.5607C10.2794 10.842 9.89782 11 9.5 11H3C2.86739 11 2.74021 11.0527 2.64645 11.1464C2.55268 11.2402 2.5 11.3674 2.5 11.5C2.5 11.6326 2.55268 11.7598 2.64645 11.8536C2.74021 11.9473 2.86739 12 3 12H9.5C10.1628 11.9992 10.7982 11.7356 11.2669 11.2669C11.7356 10.7982 11.9992 10.1628 12 9.5Z" :fill="color"/>
 </svg>
</template>

<script>
export default {
  name: 'Copy',
  props: {
    color: {
      type: String,
      default: '#8066FF',
    },
    width: {
      type: String,
      default: '12',
    },
    height: {
      type: String,
      default: '12',
    },
  },
};
</script>
