import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"credit-bank-containers"},[_c(VCol,{staticStyle:{"border":"1px solid #dedede","border-radius":"10px","background":"#f8f8f8"},attrs:{"xs":"12","sm":"12","md":"11","lg":"11","xl":"10","xxl":"10"}},[_c(VRow,{staticClass:"ma-0",staticStyle:{"border-bottom":"1px solid #dedede"}},[_c(VCol,{staticClass:"credits-heading pa-0 pb-2"},[_vm._v(" Credits ")]),_c(VCol,{staticClass:"add-payment-btn d-flex justify-end pa-0 pb-1",on:{"click":function($event){return _vm.purchaseCredits()}}},[_vm._v(" + Purchase Credits ")])],1),_c(VCol,{staticClass:"plan pb-0"},[_c('p',{staticClass:"d-flex align-center mb-2"},[_vm._v(" CREDIT BANK "),_c('span',[_c(VTooltip,{attrs:{"bottom":"","content-class":"affiliatedOrg-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"width":"16px","height":"16px","margin-left":"10px","cursor":"pointer"}},on),[_c(VImg,{attrs:{"src":require('@/assets/svg/info.svg')}})],1)]}}])},[_c('span',[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v("Total number of credits available for the purchase of digital assets or related services.")])])])],1)]),_c('div',{key:_vm.business.credits,staticClass:"count"},[_vm._v(_vm._s(_vm.business.credits))]),_c(VCol,{staticClass:"pl-0 pb-2 pt-2"},[_c('span',{staticClass:"add-payment-btn",on:{"click":function($event){_vm.showHistory = true}}},[_vm._v(" View History ")])])],1)],1)],1),(_vm.showHistory)?_c(VContainer,{attrs:{"fluid":""}},[_c('Modal',{attrs:{"persistent":"","content-class":"new-user-form","modal":_vm.showHistory,"scrollable":""}},[_c('CreditHistory',{on:{"close-modal":function($event){_vm.showHistory = false}}})],1)],1):_vm._e(),(_vm.openPurchaseCredits)?_c(VContainer,{attrs:{"fluid":""}},[_c('Modal',{attrs:{"persistent":"","content-class":"new-user-form","modal":_vm.openPurchaseCredits,"width":"1000px","scrollable":""}},[_c('PurchaseCredits',{attrs:{"cardDetails":_vm.cardDetails,"userDetails":_vm.userDetails},on:{"closemodal":_vm.closePurchaseModal}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }