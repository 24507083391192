<template>
  <div>
    <div class="credit-bank-containers">
    <v-col
    xs="12"
    sm="12"
    md="11"
    lg="11"
    xl="10"
    xxl="10"
    style="border: 1px solid #dedede;border-radius: 10px;background: #f8f8f8">
    <v-row class="ma-0" style="border-bottom: 1px solid #dedede;">
        <v-col class="credits-heading pa-0 pb-2">
        Credits
        </v-col>
        <!-- @click="purchaseCredits()" -->
        <v-col
        @click="purchaseCredits()"
        class="add-payment-btn d-flex justify-end pa-0 pb-1">
        + Purchase Credits
        </v-col>
    </v-row>
    <v-col class="plan pb-0">
      <p class="d-flex align-center mb-2">
        CREDIT BANK
      <span>
        <v-tooltip bottom content-class="affiliatedOrg-tooltip">
          <template v-slot:activator="{ on }">
            <div
              v-on="on"
              style="width: 16px; height: 16px; margin-left: 10px; cursor: pointer;"
            >
              <v-img :src="require('@/assets/svg/info.svg')" />
            </div>
          </template>
          <span>
            <div style="max-width: 200px;">Total number of credits available for
            the purchase of digital assets or related services.</div>
          </span>
        </v-tooltip>
      </span>
      </p>
      <div class="count" :key="business.credits">{{ business.credits }}</div>
      <v-col class="pl-0 pb-2 pt-2">
        <span class="add-payment-btn" @click="showHistory = true">
          View History
        </span>
      </v-col>
    </v-col>
    </v-col>
    </div>
    <v-container
      fluid
      v-if="showHistory"
    >
      <Modal
        persistent
        content-class="new-user-form"
        :modal="showHistory"
        scrollable
      >
        <CreditHistory
        @close-modal="showHistory = false"/>
      </Modal>
    </v-container>
    <v-container
      fluid
      v-if="openPurchaseCredits"
    >
      <Modal
        persistent
        content-class="new-user-form"
        :modal="openPurchaseCredits"
        width="1000px"
        scrollable
      >
        <PurchaseCredits
          :cardDetails="cardDetails"
          :userDetails="userDetails"
          @closemodal="closePurchaseModal"
        />
      </Modal>
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import Modal from '@/components/common/Modal';
import CreditHistory from '@/components/credits/History';
import PurchaseCredits from '@/components/dashboard/screens/client/PurchaseCredits';

export default {
  components: {
    Modal,
    CreditHistory,
    PurchaseCredits,
  },
  props: {
    cardDetails: {
      type: Array,
    },
  },
  data() {
    return {
      // cardDetails: [],
      showHistory: false,
      openPurchaseCredits: false,
    };
  },
  computed: {
    ...mapGetters('business', ['business']),
    ...mapGetters('user', ['userDetails']),
    businessId() {
      return _.get(this.userDetails, 'currentBusinessId');
    },
  },
  methods: {
    ...mapActions('user', ['paymentMethods']),
    ...mapActions('profile', ['getProfile']),
    ...mapActions('business', ['getBusiness', 'getReasons', 'getCreditsHistory']),
    purchaseCredits() {
      this.openPurchaseCredits = true;
    },
    closePurchaseModal() {
      this.openPurchaseCredits = false;
      this.$router.replace({
        query: {},
      });
      this.$emit('reload-cards');
      this.reloadList();
    },
    async reloadList() {
      await this.getProfile();
      await this.getBusiness(this.businessId);
      await this.getReasons();
      await this.getCreditsHistory({ businessId: this.businessId });
    },
  },
  async mounted() {
    this.reloadList();
  },
};
</script>

<style lang="scss" scoped>
    .credit-bank-containers {
        .add-payment-btn {
            font-size: 14px !important;
            color: $primary1;
            cursor: pointer;
        }
    .credits-heading {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
    .plan {
    p {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #999999;
      margin-bottom: 0px !important;
    }
    .count {
      font-weight: 600;
      font-size: 24px;
      color: #262626;
    }
    .history {
      cursor: pointer;
      width: max-content;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
    .plan-type {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #262626;
      .plan-desc {
        padding-left: 10px;
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #96A9A9
      }
    }
    .billingLink {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $secondary1;
      cursor: pointer;
    }
  }
  }
</style>
