<template>
    <div>
        <v-col class="pt-0">
                <v-row v-if="index === 0" style="border-bottom: 1px solid #d8d8d8;">
                  <v-col class="pl-0" cols="3" xs="3" sm="3" md="2" lg="2" xl="2">Date</v-col>
                  <v-col cols="5" xs="5" sm="5" md="6" lg="6" xl="7">Description</v-col>
                  <v-col cols="2" xs="2" sm="2" md="2" lg="2" xl="1">Amount</v-col>
                  <v-col cols="2"></v-col>
                </v-row>
                <v-row
                  :class="index === lastIndex ? 'pb-5' : ''"
                  :style="{
                    'border-bottom' : index === lastIndex ? '1px solid #d8d8d8' : '',
                    'padding-top' : index === 0 ? '20px' : '',
                  }">
                  <v-col
                    :key="invoiceList.createdAt"
                    class="pl-0 pt-0 pb-1" cols="3" xs="3" sm="3" md="2" lg="2" xl="2">
                    {{ dateConvert(invoiceList.createdAt) }}
                  </v-col>
                  <v-col class="pt-0 pb-1" cols="5" xs="5" sm="5" md="6" lg="6" xl="7">
                    {{ `${invoiceList.description} (${invoiceList.credits} credits)` }}
                  </v-col>
                  <v-col class="pt-0 pb-1" cols="2" xs="2" sm="2" md="2" lg="2" xl="1">
                    ${{ invoiceList.amount.toLocaleString() }}.00</v-col>
                  <v-col
                    class="cursor-pointer pt-0 pb-1 text-right"
                    cols="2">
                    <a
                      v-if="$vuetify.breakpoint.lgAndUp"
                      class="pl-5"
                      @click="createPDF(invoiceList)"
                      target="_blank"><u>View</u></a>
                    <span
                      class="pt-1"
                      @click="createPDF(invoiceList, 1)"
                      :class="$vuetify.breakpoint.lgAndUp ? 'pl-1' : 'pl-5'"
                    >
        <Copy/>
        </span>
                  </v-col>
                </v-row>
              </v-col>
    </div>
</template>

<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { mapActions } from 'vuex';
import Copy from '@/components/common/svg/Copy';

const bslogo = require('@/assets/favicon.png');

export default {
  components: {
    Copy,
  },
  props: {
    invoiceList: {
      type: Object,
    },
    index: {
      type: Number,
    },
    lastIndex: {
      type: Number,
    },
  },
  data() {
    return {
      pdfLink: '',
    };
  },
  methods: {
    ...mapActions('user', ['pdfFormat']),
    ...mapActions(['setNotification']),
    async createPDF(val, val2) {
      const result = await this.pdfFormat(val.id);
      const { invoice } = result;
      // eslint-disable-next-line
      const doc = new jsPDF();
      doc.setProperties({
        title: 'Receipt',
      });
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(20);
      doc.text('Receipt', 10, 20);
      doc.addImage(bslogo, 'PNG', 190, 11, 11, 12);
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      // eslint-disable-next-line
      const { credits } = invoice;
      const InvoiceHeaders = [
        'Invoice number',
        'Date paid',
        'Credits',
      ];
      const textData = [
        invoice.number,
        moment(invoice.createdAt).format('MMMM DD, YYYY'),
        `${credits}`,
      ];
      if (invoice.paymentmethod !== null) {
        InvoiceHeaders.splice(2, 0, 'Payment method');
        textData.splice(2, 0, `${invoice.paymentmethod.brand} - ${invoice.paymentmethod.last4}`);
      }
      const bsDetails = [
        invoice.billingAddress.firstName,
        invoice.billingAddress.addressLine1,
        `${invoice.billingAddress.city}, ${invoice.billingAddress.state} ${invoice.billingAddress.postalCode}`,
        invoice.billingAddress.country,
        invoice.billingAddress.phoneNo,
        invoice.billingAddress.email,
      ];
      const userDetails = [
        'Bill to',
        invoice.user.email,
        `${invoice.user.firstName} ${invoice.user.lastName}`,
      ];
      const div = 5;
      let boxy = 30;
      InvoiceHeaders.forEach((line) => {
        doc.text(line, 10, boxy);
        boxy += div;
      });
      let box2 = 30;
      textData.forEach((line) => {
        doc.text(line, 40, box2);
        box2 += div;
      });
      let box3 = 60;
      bsDetails.forEach((line, index) => {
        if (index === 0) {
          doc.text(line, 10, box3);
        }
        doc.text(line, 10, box3);
        box3 += div;
      });
      let box4 = 60;
      userDetails.forEach((line, index) => {
        if (index === 0) {
          doc.text(line, 80, box4);
        }
        doc.text(line, 80, box4);
        box4 += div;
      });
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(15);
      doc.text(`$${invoice.amount}.00 paid on ${moment(invoice.createdAt).format('MMMM DD, YYYY')}`, 10, 95);
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.text(invoice.description, 10, 102);
      doc.setFontSize(8);
      doc.text('Description', 10, 120);
      doc.text('Unit price', 70, 120);
      doc.text('Qty', 100, 120);
      doc.text('Per credit', 125, 120);
      doc.text('Credits', 150, 120);
      doc.text('Amount', 180, 120);
      doc.setLineWidth(0.2);
      doc.line(10, 122, 200, 122);
      doc.setFontSize(10);
      doc.text('Bundle Price', 10, 129);
      doc.text(`${invoice.bundle.perCredit}`, 125, 129);
      if (invoice.coupon === null) {
        doc.text(`$${invoice.bundle.perCredit * invoice.credits}.00`, 70, 129);
        doc.text(`  $${invoice.bundle.perCredit * invoice.credits}.00`, 180, 129);
        doc.text(`  ${invoice.credits}`, 150, 129);
        doc.text(`  ${invoice.credits}`, 150, 141);
      }
      // eslint-disable-next-line
      let couponAmount = invoice.bundle.perCredit * invoice.credits;
      if (invoice.coupon !== null) {
        if (invoice.couponType === 'Discount per Credits') {
          doc.text(`$${invoice.bundle.perCredit * invoice.credits}.00`, 70, 129);
          doc.text(`  $${invoice.bundle.perCredit * invoice.credits}.00`, 180, 129);
          doc.text(`  ${invoice.credits}`, 150, 129);
          doc.text(`${invoice.coupon.name} ($${invoice.coupon.amount / 100} per credit)`, 10, 135);
          doc.text(`- $${couponAmount - invoice.amount}.00`, 180, 135);
          doc.text(`${invoice.coupon.amount / 100}`, 125, 141);
          doc.text(`  ${invoice.credits}`, 150, 141);
          doc.text(`  $${invoice.amount}.00`, 180, 141);
          doc.text(`  ${invoice.credits}`, 150, 153);
        } else {
          const couponCredits = invoice.credits - (invoice.coupon.amount / 100);
          doc.text(`  ${couponCredits}`, 150, 129);
          doc.text(`$${invoice.bundle.perCredit * couponCredits}.00`, 70, 129);
          doc.text(`  $${invoice.bundle.perCredit * couponCredits}.00`, 180, 129);
          doc.text(`${invoice.coupon.name} (${invoice.coupon.amount / 100} extra credits)`, 10, 135);
          doc.text(`${invoice.bundle.perCredit}`, 125, 135);
          doc.text(`+${invoice.coupon.amount / 100}`, 150, 135);
          doc.text(`  ${invoice.credits}`, 150, 146);
        }
      }
      const priceData = [
        {
          name: `${invoice.quantity}`,
          price: '',
          amount: '',
        },
        { name: '', price: 'Subtotal', amount: `${invoice.amount}` },
        { name: '', price: 'Total', amount: `${invoice.amount}` },
        { name: '', price: 'Amount paid', amount: `${invoice.amount}` },
      ];
      if (invoice.coupon !== null) {
        if (invoice.couponType === 'Discount per Credits') {
          priceData.splice(1, 0, { name: '', price: '', amount: '' });
        }
        priceData.splice(1, 0, { name: '', price: '', amount: '' });
      }
      let box5 = 129;
      const div2 = 6;
      priceData.forEach((line, index) => {
        // if (priceData[priceData.length - 1].name === line.name) {
        //   doc.setFont('helvetica', 'bold');
        //   doc.text(line.name, 100, box5);
        // }
        // if (index === 0) {
        //   doc.setDrawColor('#d8d8d8');
        //   doc.line(10, box5 + 1, 200, box5 + 1);
        // }
        doc.text(line.name, 100, box5);
        // if (index !== priceData.length - 1) {
        //   doc.setDrawColor('#d8d8d8');
        //   doc.line(70, box5 + 1, 200, box5 + 1);
        // }
        box5 += div2;
        if (index === priceData.length - 1) {
          box5 = 129;
        }
      });
      priceData.forEach((line, index) => {
        if (priceData[priceData.length - 1].price === line.price) {
          doc.setFont('helvetica', 'bold');
          doc.text(line.price > 0 ? `$${line.price}.00` : line.price, 70, box5);
        }
        if (priceData[priceData.length - 1].price !== line.price) {
          doc.setFont('helvetica', 'normal');
          doc.text(line.price > 0 ? `$${line.price}.00` : line.price, 70, box5);
        }
        box5 += div2;
        if (index === priceData.length - 1) {
          box5 = 129;
        }
      });
      priceData.forEach((line, index) => {
        if (priceData.length - 1 === index) {
          doc.setFont('helvetica', 'bold');
          doc.text(line.amount > 0 ? `  $${line.amount}.00` : '', 180, box5);
        }
        doc.setFont('helvetica', 'normal');
        doc.text(line.amount > 0 ? `  $${line.amount}.00` : '', 180, box5);
        box5 += div2;
      });
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(8);
      const totalPages = doc.getNumberOfPages();
      // eslint-disable-next-line
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setDrawColor('#d8d8d8');
        doc.line(10, 280, 200, 280);
        doc.text(`${invoice.number} . $${invoice.amount}.00 paid on ${moment(invoice.createdAt).format('MMMM DD, YYYY')}`, 10, 285);
        doc.text(`Page ${i} of ${totalPages}`, 185, 285);
      }
      // eslint-disable-next-line
      this.pdfLink = doc.output('bloburl');
      if (val2 === 1) {
        this.setNotification({
          message: 'Link copied to clipboard.',
          type: 'success',
        }, { root: true });
        return navigator.clipboard.writeText(this.pdfLink);
      }
      return window.open(this.pdfLink);
    },
    dateConvert(val) {
      const date = moment(val).format('M/DD/YYYY');
      return date;
    },
  },
};
</script>
