<!-- eslint-disable max-len -->
<!-- eslint-disable -->
<template>
  <div>
    <Loader v-if="showLoading" />
    <div class="purchase-container" v-if="!showLoading">
        <v-row class="ma-auto model-header">
          <v-col class="d-flex align-center py-0 pl-0" cols="10">
            <span class="text modal-header-title">Purchase Credits</span>
          </v-col>
          <v-col class="d-flex justify-end py-0 pr-0">
            <v-icon color="darken-1" @click="$emit('closemodal')">mdi-close</v-icon>
          </v-col>
      </v-row>
      <v-col
        v-if="loading && setStripe"
        cols="12"
        style="height: 300px;padding: 100px;"
        class="d-flex justify-center">
        <div v-if="loading && !setStripeloading">
          <div class="d-flex justify-center">
            <v-progress-circular
              indeterminate
              color="#929292"
            ></v-progress-circular>
          </div>
          <div>
            Purchasing Credits...
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        v-if="!setStripe"
        style="height: 300px;padding: 100px;" class="d-flex justify-center">
        <div>
        <div class="d-flex justify-center"><img src="@/assets/checkGreen.png" width="40"/></div>
        <div style="font-size: 20px;color: #09BA51;">
          <div class="d-flex justify-center">Credits Successfully Purchase.</div>
          <div class="d-flex justify-center">Dialog Will automatically close in 5 seconds.</div>
        </div>
      </div>
    </v-col>
      <div v-if="!loading && setStripe">
      <v-row class="ma-0 scollable-div">
      <v-col cols="12" sm="12" xs="12" md="7" lg="7" xl="7" xxl="7">
        <v-col class="ma-0 pt-0 pr-0">
          <v-col class="pl-0 pr-0">
          <span
            class="d-flex no-wrap">
            <span class="creative-heading ml-1">select credits bundle</span>
            <p style="width: 67%;" class="line mb-0 mb-3 pt-2 ml-2"></p>
          </span>
          </v-col>
          <v-radio-group
            :key="options"
            v-model="options"
            hide-details
          >
          <v-col
            :style="{ 'background' : index === options ? '#f2f2f2' : 'white' }"
            class="ma-2 ml-0 d-flex bundle-select"
            v-for="(item, index) in bundlesList" :key="index">
            <v-radio
              color="info"
              :class="item.id === 1 ? 'mb-10' : 'mb-6'"
              :style="{
                'margin-bottom' : creditsError.length > 0 && item.id === 1 ? '60px !important' : ''
              }"
              @click="selectPlan(index, item)"
              :key="options"
              :value="index === options ? options : ''"
            ></v-radio>
            <v-row
              class="ma-0 cursor-pointer"
              @click="selectPlan(index, item)">
              <v-col cols="8" class="pa-0">
                  <div class="bundle-name">{{ textCapitalize(item.name) }} Bundle</div>
                  <div class="pa-0">
                    <span class="text-purple">${{ item.perCredit }}/credit </span><span
                      v-if="item.credits > 0"
                      class="text-gray">
                      ({{ item.credits }} x ${{ item.perCredit }} = {{ (item.credits * item.perCredit).toLocaleString() }})</span>
                  </div>
                </v-col>
                  <v-col class="d-flex justify-end pa-0">
                    <v-col
                      v-if="item.id === 1"
                      cols="12"
                      xs="12"
                      sm="12"
                      md="8"
                      lg="8"
                      xl="8"
                      xxl="8"
                      class="pa-0 credits-input">
                    <label>QTY</label>
                    <v-text-field
                        v-if="item.id === 1"
                        class="type-selection"
                        outlined
                        dense
                        name="payout"
                        @change="checkCredits(item)"
                        @input="checkCredits(item)"
                        @blur="checkCredits(item)"
                        v-model="item.credits"
                        min="1"
                        oninput=
                          "validity.valid || (value = value.slice(0, value.length-2))
                          if (value.length > 10) value = value.slice(0, value.length-1);
                          "
                        type="number"
                    ></v-text-field>
                    <span
                      style="color: red;font-size: 14px;"
                      v-if="creditsError.length">{{ creditsError }}</span>
                  </v-col>
                    <span v-else style="font-size: 34px;">{{ item.credits }}</span>
                  </v-col>
            </v-row>
          </v-col>
          </v-radio-group>
        </v-col>
        <v-col class="pt-0 pr-0 pb-0">
          <v-col class="pl-0 pr-0">
          <span
            class="d-flex no-wrap">
            <span class="creative-heading ml-1">select payment method&nbsp;</span>
            <p class="line mb-0 mb-3 pt-2 ml-2"></p>
          </span>
          </v-col>
          <v-radio-group
            v-model="cardOptions"
            hide-details
            :key="cardDetails"
          >
          <v-col
            :style="{ 'background' : card.id === cardOptions ? '#f2f2f2' : 'white' }"
            class="ma-2 ml-0 d-flex bundle-select pb-0"
            v-for="card in cardDetails" :key="card">
            <v-radio
              class="pb-7"
              color="info"
              @click="selectCard(card.id, card)"
              :value="card.id === cardOptions ? cardOptions : ''"
            ></v-radio>
          <v-row
            @click="selectCard(card.id, card)"
            class="ma-0 mb-2 pr-0 cursor-pointer"
            :key="card">
                <div cols="2" class="pr-2">
                <img
                  class="image"
                  :src="require(`@/assets/cardIcons/${card.brand}.svg`)"
                  width="60"/>
                </div>
                <v-col cols="6" xl="7" class="pa-0 pl-3">
                  <p
                    style="font-size: 14px;font-weight: 700;"
                    class="mb-0"
                    ><span class="text-capitalize">
                      {{ card.brand }}</span> ending in {{ card.last4 }}</p>
                  <p
                  class="mb-0">
                  Expiry {{ card.expMonth > 9
                    ? card.expMonth : `0${card.expMonth}` }}/
                  {{ card.expYear }}</p>
                </v-col>
                <v-col class="d-flex justify-end pb-4">
                  <div
                    class="saved-tag">
                    saved
                  </div>
                </v-col>
              </v-row>
          </v-col>
          <v-col :style="{ 'background' : cardOptions === 'other' || !cardDetails.length ? '#f2f2f2' : 'white' }"
            class="ma-2 ml-0 bundle-select"
            @click="selectCard('other', '')">
            <v-radio
              label="Other Payment Method"
              class="OtherCard-radio"
              color="info"
              :value="cardOptions === 'other' ? cardOptions : ''"
            ></v-radio>
            <div v-if="!cardDetails.length || cardOptions === 'other'">
              <v-col class="pb-0">
                <div class="carddetails-container">
    <v-row
      :style="{
        'border': numberErr.length > 0 ? '1px solid red' : '1px solid #dedede'
      }"
      class="cardnumber-div ma-0" style="padding-top: 6px;padding-bottom: 4px;">
      <v-col
        cols="5"
        style="padding-top: 5px;padding-bottom: 3px;"
        id="card-number" ref="cardnumber"></v-col>
          <v-col
            :style="{
              'padding-bottom' : cardCom ? '5px' : '0px',
            }"
            style="padding-top: 1px;padding-right: 10px;"
            class="pl-0 d-flex justify-end">
            <div v-if="!cardCom">
              <img
                class="image"
                :src="require(`@/assets/cardIcons/visa.svg`)"
                width="40"/>
              <img
                v-if="$vuetify.breakpoint.lgAndUp"
                class="image image2"
                :src="require(`@/assets/cardIcons/mastercard.svg`)"
                width="40"/>
              <img
                class="image"
                :src="require(`@/assets/cardIcons/discover.svg`)"
                width="40"/></div>
              <img
                :style="{
                  'padding-bottom' : !cardCom ? '5px' : '0px',
                }"
                class="image"
                width="40"
                :src="require(`@/assets/cardIcons/${cardType}.svg`)"/>
            </v-col>
    </v-row>
    <span class="error-message" v-if="numberErr.length > 0">{{ numberErr }}</span>
      <v-row class="ma-0">
        <v-col cols="6" class="pl-0">
        <div
          :style="{
            'border': expiryErr.length > 0 ? '1px solid red' : '1px solid #dedede'
          }"
          class="cardnumber-div pa-3" id="card-expiry">
        </div>
        <span class="error-message" v-if="expiryErr.length > 0">{{ expiryErr }}</span>
        </v-col>
        <v-col cols="6" class="pr-0">
        <div
          :style="{
            'border': cvcErr.length > 0 ? '1px solid red' : '1px solid #dedede'
          }"
          class="cardnumber-div pa-3" id="card-cvc"></div>
        <span class="error-message" v-if="cvcErr.length > 0">{{ cvcErr }}</span>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="6" class="pl-0">
          <v-select
            solo
            flat
            class="type-selection mb-n3 notranslate"
            placeholder="Country"
            :items="countriesList"
            v-model="country"
            item-text="label"
            item-value="value"
            append-icon="mdi-chevron-down"
          ></v-select>
        </v-col>
        <v-col cols="6" class="pr-0">
          <div
            :style="{
              'border': postalCodeErr.length > 0 ? '1px solid red' : '1px solid #dedede'
            }"
            class="cardnumber-div pa-3" id="card-postal"></div>
        <span class="error-message" v-if="postalCodeErr.length > 0">{{ postalCodeErr }}</span>
        </v-col>
      </v-row>
      <div>
        <v-checkbox
          v-model="selected"
          :disabled="!checkForm"
          label="Save as primary payment method for future purchase"
          class="mt-3 checkbox"
        >
        </v-checkbox>
      </div>
  </div>
              </v-col>
            </div>
          </v-col>
          </v-radio-group>
        </v-col>
        <v-col v-if="errorMessage.length > 0" class="pt-0">
          <span style="color: red;">{{ errorMessage }}</span>
        </v-col>
      </v-col>
      <v-col>
        <v-col
          v-if="selectedBundle"
          cols="12"
          sm="12"
          xs="12"
          md="8"
          lg="12"
          xl="12"
          xxl="6"
          class="bundle-select fix-div">
          <v-col class="pl-0 pr-0">
          <span
            class="d-flex no-wrap">
            <span class="creative-heading">Purchase summary</span>
            <p style="width: 195px;" class="line mb-0 mb-3 pt-2 ml-2"></p>
          </span>
          </v-col>
          <v-row class="ma-0">
            <v-col class="pa-0">
              <div>{{ textCapitalize(selectedBundle.name) }}</div>
              <div class="text-gray">${{ selectedBundle.perCredit }}/credit</div>
            </v-col>
            <v-col class="pa-0 d-flex justify-center">
              {{ selectedBundle.credits }}
            </v-col>
            <v-col
            class="pa-0 d-flex justify-end">
            ${{ (selectedBundle.credits * selectedBundle.perCredit).toLocaleString() }}.00</v-col>
          </v-row>
          <v-row style="border-top: 1px solid #d1d1d1;" class="mt-2 ma-0 text-gray">
            <v-col class="pl-0">Subtotal</v-col>
            <v-col class="d-flex justify-end pr-0">
              ${{ (selectedBundle.credits * selectedBundle.perCredit).toLocaleString() }}.00
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col
              v-if="!addcoupon && !promocode.length"
              class="pl-0">
              <span
                class="text-purple cursor-pointer"
                @click="addCoupons()">+ Add Coupon</span>
            </v-col>
            <v-row
              :style="{ 'border': `1px solid ${errorColor}` }"
              class="ma-0 coupon-input" v-if="addcoupon">
              <v-col class="pa-0">
                <v-text-field
                  class="type-selection mt-1 pl-4"
                  outlined
                  placeholder="Coupon Code"
                  dense
                  v-model="promocode"
                ></v-text-field>
              </v-col>
              <v-col
                class="cursor-pointer d-flex justify-end"
                @click="submitCoupon()"
                style="color: #8066ff;font-size: 15px;font-weight: 600;">
                Apply
              </v-col>
            </v-row>
            <div v-if="addcoupon">
            <div
              style="color: red;"
              v-if="!couponApplied && couponError.length > 0">
              {{ couponError }}
            </div>
            </div>
            <v-row class="ma-0" v-if="promocode.length && !addcoupon">
              <v-col class="pl-0">
                <div
                  style="background: #f5f5f5;
                    width: fit-content;
                    font-size: 13px;
                    font-weight: 600;"
                  class="saved-tag d-flex">
                  {{ promocode }}
                  <span
                    class="pl-5 cursor-pointer"
                    style="color: rgb(168 166 166);"
                    @click="removeCoupon()">
                    X
                  </span>
                </div>
                <div class="pt-1 pl-1">
                  {{ couponApplyData.affective === 'Add Extra Credits'
                    ? `${couponApplyData.discount} Extra Credits`
                      : `$${couponApplyData.discount} Discount per credit` }}</div>
              </v-col>
              <v-col lg="4" xl="4" class="d-flex justify-end pr-0" v-if="couponApplyData.discount > 0">
                {{ couponApplyData.affective
                  === 'Add Extra Credits' ? `+ ${couponApplyData.discount}`
                    : `- $${((selectedBundle.credits * selectedBundle.perCredit)
                      - (couponApplyData.discount * selectedBundle.credits)).toLocaleString() }.00` }}
              </v-col>
            </v-row>
          </v-row>
          <v-row style="border-top: 1px solid #d1d1d1;" class="ma-0 mt-2 bundle-name">
            <v-col class="pl-1">Total</v-col>
            <v-col v-if="couponApplyData.affective !== 'Discount per Credits'" class="d-flex justify-end pr-0">
              ${{ (selectedBundle.credits * selectedBundle.perCredit).toLocaleString() }}.00
            </v-col>
            <v-col v-if="couponApplyData.affective === 'Discount per Credits'" class="d-flex justify-end pr-0">
              ${{ (couponApplyData.discount * selectedBundle.credits.toLocaleString()) }}.00
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      </v-row>
      <v-col class="d-flex justify-end" style="border-top: 1px solid #d1d1d1;">
          <div class="continue-btn text-center">
              <v-btn
                text
                class="cancel mr-5"
                @click="$emit('closemodal')">Cancel
              </v-btn>
              <v-btn
                class="register-button btn-purple"
                type="submit"
                :loading="loader"
                @click="submitForm"
                :disabled="!checkAccess"
              >Purchase
              </v-btn>
            </div>
      </v-col>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import Loader from '@/components/common/Loader';
import countries from '../../../../plugins/HyperTranslate/countries.json';

export default {
  components: {
    Loader,
  },
  props: {
    userDetails: {
      type: Object,
    },
    cardDetails: {
      type: Object,
    },
    buyCredits: {
      type: Number,
    },
  },
  data() {
    return {
      errorMessage: '',
      setStripeloading: '',
      setStripe: true,
      stripe: null,
      loader: false,
      showLoading: true,
      addcoupon: false,
      promocode: '',
      loading: false,
      options: 1,
      selected: false,
      cardError: false,
      cardNumber: '',
      cardType: 'american express',
      countriesList: [],
      country: 'US',
      bundlesList: [],
      selectedBundle: '',
      couponApplyData: {},
      couponApplied: false,
      couponError: '',
      selectedOther: true,
      selectedCard: '',
      selectedOtherCard: '',
      cardOptions: 0,
      creditsError: '',
      checkOtherCard: true,
      numberErr: '',
      expiryErr: '',
      cvcErr: '',
      postalCode: null,
      postalCodeErr: '',
      cardCom: false,
      token: null,
      cardExpiry: null,
      cardCvc: null,
      numberField: false,
      expiryField: false,
      cvcField: false,
      postalCodeField: false,
    };
  },
  computed: {
    stripeElements() {
      return this.$stripe.elements();
    },
    errorColor() {
      if (this.couponError.length) {
        return 'red';
      }
      return '#8066ff';
    },
    checkForm() {
      if (this.numberErr.length > 0
          || this.cvcErr.length > 0 || this.expiryErr.length > 0 || this.postalCodeErr.length > 0) {
        // eslint-disable-next-line
        this.selected = false;
        return false;
      }
      return true;
    },
    checkAccess() {
      if ((this.selectedCard.paymentMethodId
        || (this.selected === true))
        && this.creditsError === '') {
        return true;
      }
      if (this.cardError) {
        return false;
      }
      return false;
    },
  },
  methods: {
    ...mapActions('user', ['setBundles', 'paymentMethod', 'applyCoupons']),
    getRandomCardType() {
      const cardTypes = ['american express', 'jcb', 'diners', 'elo', 'unionpay'];
      const randomIndex = Math.floor(Math.random() * cardTypes.length);
      return cardTypes[randomIndex];
    },
    addCoupons() {
      this.addcoupon = true;
      this.couponError = '';
    },
    async submitCoupon() {
      const payload = {
        couponCode: this.promocode,
        price: this.selectedBundle.price === 0
          ? this.selectedBundle.perCredit * Number(this.selectedBundle.credits)
          : this.selectedBundle.price,
        bundle: this.selectedBundle.name,
      };
      const result = await this.applyCoupons(payload);
      if (result.success) {
        this.addcoupon = false;
        this.couponApplied = true;
        this.couponApplyData = result;
      }
      if (result.data.error) {
        this.couponApplied = false;
        this.addcoupon = true;
        this.couponError = result.data.error;
      }
    },
    removeCoupon() {
      this.promocode = '';
      this.couponApplyData = '';
      this.couponApplied = false;
      this.addcoupon = false;
    },
    getCardDetails(val) {
      this.selectedOther = val.selected;
      this.selectedOtherCard = val.card;
      this.checkOtherCard = val.fillCard;
    },
    checkCredits(val) {
      // eslint-disable-next-line
      val.credits = Number(val.credits);
      if (val.credits === '' || val.credits === 0) {
        this.creditsError = 'Enter credits';
      } else {
        this.creditsError = '';
      }
    },
    async stripeCard() {
      const style = {
        base: {
          color: 'black',
          fontSize: '16px',
          fontWeight: 400,
          // '::placeholder': {
          //   color: '#aab7c4',
          // },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      };
      this.cardNumber = await this.stripeElements.create('cardNumber', {
        style,
        placeholder: 'Card Number',
      });
      this.cardNumber.mount('#card-number');
      this.cardNumber.on('change', (event) => {
        if (event.complete) {
          this.numberField = true;
          this.numberErr = '';
          this.cardCom = event.complete;
          this.cardType = event.brand;
        } else {
          if (event.empty) {
            this.numberErr = 'Your card number is incomplete';
          }
          if (event.error) {
            this.numberErr = event.error.message;
          }
          this.cardType = null;
        }
      });
      this.cardExpiry = await this.stripeElements.create('cardExpiry', {
        style,
        placeholder: 'Expiration (MM / YY)',
      });
      this.cardExpiry.mount('#card-expiry');
      this.cardExpiry.on('change', (event) => {
        if (event.complete) {
          this.expiryField = true;
          this.expiryErr = '';
        } else {
          if (event.empty) {
            this.expiryErr = 'Your expiry date is incomplete';
          }
          if (event.error.message) {
            this.expiryErr = 'Your card expiry date is invalid';
          }
        }
      });
      this.cardCvc = await this.stripeElements.create('cardCvc', { style });
      this.cardCvc.mount('#card-cvc');
      this.cardCvc.on('change', (event) => {
        if (event.complete) {
          this.cvcField = true;
          this.cvcErr = '';
        } else {
          if (event.empty) {
            this.cvcErr = 'Your cvc number is incomplete';
          }
          if (event.error.message) {
            this.cvcErr = 'Your cvv is invalid';
          }
        }
      });
      this.postalCode = await this.stripeElements.create('postalCode', {
        style,
        placeholder: 'Zip',
      });
      this.postalCode.mount('#card-postal');
      this.postalCode.on('change', (event) => {
        if (event.complete) {
          if (event.value.length > 7) {
            this.postalCodeErr = 'Your postal code is invalid';
          } else {
            this.postalCodeErr = '';
          }
          this.postalCodeField = true;
        } else {
          this.postalCodeErr = 'Your postal code is incomplete';
        }
      });
      this.countriesList = countries;
      setInterval(() => {
        if (!this.cardCom) {
          this.cardType = this.getRandomCardType();
        }
      }, 3000);
    },
    async selectCard(val, val2) {
      this.cardOptions = val;
      this.selectedCard = val2;
      if (val !== 'other') {
        this.token = '';
        this.selected = false;
        this.cardNumber.destroy();
        this.cardExpiry.destroy();
        this.cardCvc.destroy();
        this.postalCode.destroy();
      }
      if (val === 'other') {
        this.selectedOtherCard = '';
        this.stripeCard();
      }
      this.checkCredits(this.selectedBundle);
    },
    textCapitalize(val) {
      const value = val[0].toUpperCase() + val.slice(1).toLowerCase();
      return value;
    },
    selectPlan(index, val) {
      this.options = index;
      this.selectedBundle = val;
      if (val.id !== 1) {
        this.creditsError = '';
      }
      this.promocode = '';
      this.couponApplyData = {};
      this.couponApplied = false;
    },
    setPrice(val) {
      if (val.price === 0 && this.couponApplyData.affective !== 'Discount per Credits') {
        // eslint-disable-next-line
        return val.perCredit * Number(val.credits);
      }
      if (this.couponApplied) {
        if (this.couponApplyData.affective === 'Discount per Credits') {
          if (val.price === 0) {
            return this.couponApplyData.discount * Number(val.credits);
          }
          // eslint-disable-next-line
          return this.couponApplyData.discount * val.credits;
        }
      }
      // eslint-disable-next-line
      return val.price;
    },
    async submitForm() {
      if (this.selected && !this.selectedCard.paymentMethodId
        && (this.cardOptions === 'other' || this.errorMessage.length > 0)) {
        this.loader = true;
        const { token, error } = await this.$stripe.createToken(this.cardNumber);
        if (error) {
          this.loader = false;
          this.selected = false;
          // handle error here
          this.cardError = true;
          this.errorMessage = error.message;
          return;
        }
        if (token.card) {
          this.errorMessage = '';
          this.token = token;
        }
      }
      this.checkCredits(this.selectedBundle);
      if (this.creditsError === '') {
        this.loader = true;
        const payload = {
          customerId: this.userDetails.customerId,
          firstName: this.userDetails.firstName,
          lastName: this.userDetails.lastName,
          email: this.userDetails.email,
          businessId: this.userDetails.currentBusinessId,
          bundleId: this.selectedBundle.id,
          credits:
            this.couponApplied && this.couponApplyData.affective === 'Add Extra Credits'
              ? (this.couponApplyData.discount + this.selectedBundle.credits)
              : this.selectedBundle.credits,
          price: this.setPrice(this.selectedBundle),
          applyCouponCode: this.couponApplied,
          priority: this.selectedCard.paymentMethodId ? true : null,
          paymentMethodId: this.selectedCard.paymentMethodId
            ? this.selectedCard.paymentMethodId : null,
          couponId: this.couponApplied ? this.promocode : '',
          priceId: '',
          productId: '',
          otherPaymentMethod: this.token ? {
            address: {
              country: this.token.card.country,
              postal_code: this.token.card.address_zip,
            },
            token: this.token.id,
            brand: this.token.card.brand.toLowerCase(),
            businessId: this.userDetails.currentBusinessId,
            cardNumber: this.token.card.last4,
            expMonth: this.token.card.exp_month,
            expYear: this.token.card.exp_year,
            priority: true,
          } : null,
        };
        const result = await this.paymentMethod(payload);
        if (result.success) {
          if (result.paymentIntent.next_action === null) {
            this.loading = true;
            setInterval(() => {
              this.setStripe = false;
              this.setStripeloading = setInterval(() => {
                this.$emit('closemodal');
                this.$emit('reload-cards');
              }, 8000);
            }, 3000);
            // eslint-disable-next-line
          }
          if (result.paymentIntent.next_action && result.paymentIntent.client_secret) {
            // eslint-disable-next-line
            const { paymentIntent, error } = await this.stripe.confirmCardPayment(result.paymentIntent.client_secret, {
              payment_method: result.paymentIntent.payment_method,
            });
            if (error) {
              this.errorMessage = error.message;
              this.loader = false;
            } else if (paymentIntent.status === 'succeeded') {
              this.loading = true;
              setInterval(() => {
                this.setStripe = false;
                this.setStripeloading = setInterval(() => {
                  this.$emit('closemodal');
                  this.$emit('reload-cards');
                }, 8000);
              }, 3000);
            } else {
              // add a payment methods
            }
          }
        }
        if (result.data.error) {
          this.errorMessage = result.data.error;
          this.loader = false;
        }
      }
    },
  },
  async mounted() {
    this.$router.replace({
      query: {
        method: 'payment',
      },
    });
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
    const result = await this.setBundles(this.userDetails.currentBusinessId);
    this.bundlesList = result.bundles;
    this.selectedBundle = this.bundlesList.find((d) => d.name === 'FEATURE');
    const get = this.bundlesList.find((d) => d.code === 'exp' || d.code === 'expluxury');
    if (get === undefined) {
      this.options = 2;
    }
    // if (this.buyCredits > 0) {
    //   this.options = 0;
    //   this.selectedBundle = this.bundlesList.find((d) => d.name === 'A LA CARTE');
    //   this.selectedBundle.credits = this.buyCredits;
    // }
    if (this.cardDetails.length > 0) {
      this.selectedCard = this.cardDetails.find((card) => card.priority === true);
      if (this.selectedCard.paymentMethodId !== undefined) {
        this.cardOptions = this.selectedCard.id;
      }
    }
    this.showLoading = false;
    if (this.cardDetails.length === 0) {
      this.stripeCard();
    }
  },
};
</script>

<style lang="scss" scoped>
.purchase-container {
  .carddetails-container {
  font-family: 'Poppins' !important;
  .error-div {
    border: 1px solid red;
  }
  .checkbox {
    ::v-deep .v-input--selection-controls__input + .v-label {
      color: #262626 !important;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .error-message {
    font-size: 14px;
    color: red;
  }
  .image {
      padding-left: 5px;
    }
    @media (max-width: 600px) {
      .image2 {
        display: none;
      }
    }
  .cardnumber-div {
    background-color: white;
    border: 1px solid #dedede;
    border-radius: 5px;
  }
}
  .error-message {
    color: red;
    font-size: 14px;
  }
  .checkbox {
    ::v-deep .v-input--selection-controls__input + .v-label {
      color: #262626 !important;
      font-size: 14px;
      font-weight: 400;
    }
  }
  @media (max-width: 600px) {
    ::v-deep .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
      width: 50px !important;
    }
  }
  ::v-deep .type-selection {
    .image {
      padding: 2px;
    }
    .v-input__slot {
      margin-bottom: 0px;
    }
    .v-input__control {
      background-color: white;
    }
    .v-input--dense > .v-input__control > .v-input__slot {
      margin-bottom: 0px;
    }
    @media (max-width: 600px) {
      .image2 {
        display: none;
      }
    }
    .v-text-field__details {
      display: none !important;
    }
  }
  .input-field {
    padding: 5px;
    border-radius: 5px;
    background-color: white;
    ::v-deep input {
      width: 200px;
      font-size: 14px;
      line-height: 20px;
      padding-left: 5px;
      height: 35px;
      border: none !important;
    }
    :focus-visible {
    outline: none !important;
    }
  }
  @media screen and (max-width: 1000px){
    .fix-div{
      width: 100% !important;
      position: static !important;
    }
  }
  .fix-div {
    width: 370px;
    position: fixed;
  }
  .scollable-div {
    overflow: auto;
    max-height: 500px;
    .base-input {
      margin-top: 6px;
    }
  }
  .bundle-select {
    border: 1px solid #c9c9c9;
    border-radius: 5px;
  }
  .coupon-input {
    background: #f8f8f8;
    border-radius: 5px;
    ::v-deep .v-input input {
      font-family: $fontFamily1;
      font-size: 15px !important;
      font-weight: 600;
      line-height: 18px;
    }
    ::v-deep .v-input__slot {
      background: #f8f8f8;
      height: 30px;
      padding-left: 0px !important;
    }
    // ::v-deep .input-field {
    //   height: 50px;
    // }
    ::v-deep .v-text-field--outlined fieldset {
      border: none !important;
    }
    ::v-deep .v-input__control {
      min-height: 40px !important;
    }
    ::v-deep .v-text-field__details {
      display: none !important;
    }
  }
  .bundle-name {
    font-size: 16px;
    font-weight: 600;
  }
  .credits-input {
    ::v-deep .type-selection {
      .v-text-field__details {
        display: none !important;
      }
    }
  }
  .saved-tag {
    border: 2px solid #dedede;
    height: 25px;
    width: 75px;
    border-left: 5px solid #dedede;
    border-radius: 5px;
    padding: 2px 1rem;
    font-size: 13px;
    text-transform: uppercase;
    background: white;
  }
  .OtherCard-radio {
    ::v-deep .theme--light.v-label {
      color: black !important;
    }
  }
  .creative-heading {
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #999;
  text-transform: uppercase;
    }
    .line {
        width: 64%;
        border-bottom: 3px solid #d1d1d1;
    }
}
</style>
