<template>
  <v-container class="main-container pt-10 h-100">
    <Loader v-if="isLoading" />
    <div class="billing-container" v-else>
      <div> <PageTitle /> </div>
      <v-row>
        <v-col
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        xxl="12"
        >
        <v-row>
            <v-col
            class="billing-width"
            md="6"
            xs="12"
            sm="12"
            lg="7"
            xl="7"
            >
            <div>
              <div class="payment-heading">Payment Method</div>
              <span v-if="cardDetails.length > 0">
              <v-row
                class="ma-0 pa-3 mt-4 mb-2 pr-0"
                style="border: 1px solid #e0e0e0;border-radius: 5px;"
                v-for="(card, index) in cardDetails"
                :key="index">
                <div cols="2" class="pr-2">
                <img
                  class="image"
                  :src="require(`@/assets/cardIcons/${card.brand}.svg`)"
                  width="60"/>
                </div>
                <v-col cols="6" xl="6" class="pl-2 pt-0">
                  <p
                    style="font-size: 14px;font-weight: 700;"
                    class="mb-0"
                    ><span class="text-capitalize">
                      {{ card.brand }}</span> ending in {{ card.last4 }}</p>
                  <p
                  class="mb-0">
                  Expiry {{ card.expMonth > 9
                    ? card.expMonth : `0${card.expMonth}` }}/
                  {{ card.expYear }}</p>
                </v-col>
                <v-col
                  class="justify-end buttons d-flex no-wrap pr-0 pt-0"
                  cols="4" lg="4" xl="4">
                  <v-btn class="btn-white mr-2" style="min-height: 30px;"
                    @click="updatePaymentMethod(card.id)"
                  >
                    Update
                  </v-btn>
                  <v-btn
                    @click="removePaymentMethod(card, index)"
                    :loading="index === removedCard ? showLoding : false"
                    class="btn-remove" style="min-height: 30px;">
                    Remove
                  </v-btn>
                </v-col>
              </v-row>
              </span>
              <v-col
                xs="12"
                sm="12"
                md="12"
                lg="4"
                xl="3"
                xxl="3"
                v-if="cardDetails.length === 0"
                class="pl-0 pt-5 pr-0"
                :class="cardDetails.length === 0 ? 'pb-12' : 'pb-5'">
                <span class="add-payment-btn"
                @click="openPaymentMethod()">
                  + Add Payment Method
                </span>
              </v-col>
            </div>
            <div class="pt-5">
              <div class="payment-heading">Invoices</div>
              <span v-if="invoiceLists.length !== 0">
              <div
                v-for="(invoiceList, index) in Invoicelist" :key="index">
              <InvoiceList
                :key="invoiceList"
                :index="index"
                :lastIndex="Object.keys(Invoicelist).length - 1"
                :invoiceList="invoiceList"
                >
              </InvoiceList>
              </div>
              </span>
              <div v-if="invoiceLists.length > 5"
                class="addLocation"
                @click="showMore = !showMore">
                <span v-if="!showMore">
                  Show More <v-icon class="locationIcon" dense>mdi-menu-down</v-icon>
                </span>
                <span v-if="showMore">
                  Show Less <v-icon class="locationIcon" dense>mdi-menu-up</v-icon>
                </span>
            </div>
              <v-col
                v-if="!invoiceLists.length"
                style="font-size: 16px;"
                class="pl-0"><i>No transactions found</i></v-col>
            </div>
                <!-- <div>
                    <div class="text font-label">Current Plan</div>
                    <v-expansion-panels class="plans">
                        <v-expansion-panel
                        >
                            <v-expansion-panel-header>
                                <div class="d-flex flex-column">
                                    <div class="font-h5 notranslate">{{getVertical}}</div>
                                    <div class="pt-0 current-plan notranslate">
                                      {{subscription('name')}}</div>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                               <div class="cred-div">
                                   <img class="check-icon" :src="getIcon()" />
                                   <span class="sub font-body">
                                    {{subscription('credits')}} Production Credits
                                    </span>
                                </div>
                               <div
                               class="cred-div"
                               :class="{ 'opacity' : isExists('photo') === -1}"
                               >
                                   <img class="check-icon"
                                   :src="getIcon(isExists('photo'))" />
                                   <span class="sub font-body">Photo Capture</span>
                                </div>
                               <div
                               class="cred-div"
                                :class="{ 'opacity' : isExists('video') === -1}"
                                >
                                   <img class="check-icon"
                                   :src="getIcon(isExists('video'))" />
                                   <span class="sub font-body">Video Capture</span>
                                </div>
                               <div class="cred-div"
                                :class="{ 'opacity' : isExists('audio') === -1}"
                                >
                                   <img class="check-icon"
                                   :src="getIcon(isExists('audio'))" />
                                   <span class="sub font-body">Audio Capture</span>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-card class="mt-2 purchase-btn">
                        <div class="purchase-btn-info">
                          <div class="heading font-h5">Order additional assets</div>
                          <div class="text--primary font-body description">
                            Take the first step towards receiving assets. <br/>
                            Choose your subscription today!
                          </div>
                        </div>
                        <div class="action-button-wrapper">
                          <v-btn
                          class="btn-purple"
                          @click="orderAssets()"
                          >Order Assets</v-btn>
                        </div>
                    </v-card>
                    <p class="note">Need more information about your billing?
                        Contact your producer for more details.</p>
                </div> -->
            </v-col>
            <v-col
              md="3"
              xs="12"
              sm="12"
              lg="4"
              xl="4"
              xxl="4"
              class="credit-bank"
              :class="$vuetify.breakpoint.lgAndUp ? 'justify-end ml-5' : 'justify-baseline'"
              >
              <CreditBank
                :cardDetails="cardDetails"
                @reload-cards="reloadCardsList"
              ></CreditBank>
              <v-col
                xs="12"
                sm="12"
                md="11"
                lg="11"
                xl="10"
                xxl="10"
                class="mt-4"
                style="border: 1px solid #dedede;border-radius: 10px;background: #f8f8f8">
                <v-row class="ma-0" style="border-bottom: 1px solid #dedede;">
                  <v-col class="credits-heading pa-0 pb-1">
                    Need Help?
                  </v-col>
                </v-row>
                <v-col class="add-payment-btn pl-0">
                  <a href="mailto:support@blendedsense.com">
                    support@blendedsense.com
                  </a>
                </v-col>
              </v-col>
            </v-col>
            <!-- <v-col
            xs="12"
              class="d-flex"
              :class="$vuetify.breakpoint.lgAndUp ? 'justify-end' : 'justify-baseline'"
              v-if="producerCallInfo && producerCallInfo.email"
            >
                <ProducerContact
                    :displayName="producerCallInfo.displayName"
                    :avatar="producerCallInfo.avatar"
                    :email="producerCallInfo.email"
                    :callLink="producerCallInfo.callLink"
                    :callScheduled="callScheduled"
                    @handleScheduleCall="handleScheduleCall({ scheduleCallSource: 'producer' })"
                />
            </v-col> -->
        </v-row>
      </v-col>
      </v-row>
    </div>
     <!-- <v-dialog
        v-model="showScheduleCallModal"
        max-width="1280px"
      >
      <v-card
        style="border-radius: unset !important;"
        class="d-flex flex-column pa-4"
        height="90vh"
        max-height="1080px"
      >
        <div class="d-flex mb-2">
          <v-card-title class="pt-2 pb-0 pl-2 pr-0 text-capitalize modal-header-title">
            Schedule a call
          </v-card-title>
          <v-spacer></v-spacer>
          <div class="close-btn" @click="() => toggleScheduleCallModal(false)">
            <v-icon class="pr-1 cursor-pointer">
              mdi-close
            </v-icon>
          </div>
        </div>
        <div class="bs_calendly_wrapper d-flex flex-1" style="height:100%">
          <vue-calendly
            :key="calendlyKey"
            :url="producerCallInfo && appendInviteeParams(producerCallInfo.callLink)"
            ref="vueCalendly"
            style="flex:1;min-width:320px; height:100%;"
            data-processed="true"
            @load="calendlyLoading = false"
          >
          </vue-calendly>
          <div
            v-if="producerCallInfo && !producerCallInfo.isValidLink && !calendlyLoading"
            class="bs_calendly_fallback
              flex-1 d-flex align-center justify-center"
          >
            Calendly link is invalid. Please contact support.
          </div>
          <div
            class="bs_calendly_loader"
            v-if="producerCallInfo && producerCallInfo.isValidLink && calendlyLoading"
          >
            <Loader />
          </div>
        </div>
      </v-card>
      </v-dialog> -->
      <v-container
      fluid
      v-if="modalOpen"
    >
      <Modal
        persistent
        content-class="new-user-form"
        :modal="modalOpen"
        width="500px"
        scrollable
      >
        <PaymentMethod
          @reload-cards="reloadCardsList"
          @closemodal="closePaymentCard"
          :cardDetails="selectedCard"
          :userDetails="userDetails"
        />
      </Modal>
    </v-container>
    <v-container
      fluid
      v-if="openPurchaseCredits"
    >
      <Modal
        persistent
        content-class="new-user-form"
        :modal="openPurchaseCredits"
        width="1000px"
        scrollable
      >
        <PurchaseCredits
          @reload-cards="reloadCardsList"
          :cardDetails="cardDetails"
          :userDetails="userDetails"
          @closemodal="closePurchaseModal"
        />
      </Modal>
    </v-container>
    <v-container
      fluid
      v-if="showHistory"
    >
      <Modal
        persistent
        content-class="new-user-form"
        :modal="showHistory"
        scrollable
      >
        <CreditHistory
        @close-modal="showHistory = false"/>
      </Modal>
    </v-container>
      <!-- <v-container fluid v-if="modalOpen" class="modal">
      <Modal
        :modal="modalOpen"
        fullscreen
        content-class="main-modal preview-modal full-screen"
      >
      <v-card elevation="0">
          <v-card-text class="d-flex justify-start">
            <v-btn
              icon
              width="23"
              height="23"
              @click="modalOpen = false"
            >
            <span class="a-link cursor-pointer">Back ></span>
            </v-btn>
          </v-card-text>
          <v-card-text>
        <OrderAssets />-->
      <!-- </v-card-text>
        </v-card>
      </Modal>
    </v-container> -->
  </v-container>
</template>

<script>
/* eslint-disable global-require */
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import Modal from '@/components/common/Modal';
// import OrderAssets from '../components/dashboard/screens/client/OrderAssets';
// import ProducerContact from '@/components/dashboard/screens/common/ProducerContact';
import Loader from '@/components/common/Loader';
import PageTitle from '@/components/common/PageTitle';
// import Copy from '@/components/common/svg/Copy';
import CreditHistory from '@/components/credits/History';
import CreditBank from '@/components/credits/CreditBank';
import PaymentMethod from '../components/dashboard/screens/client/PaymentMethod';
import PurchaseCredits from '../components/dashboard/screens/client/PurchaseCredits';
import InvoiceList from '../components/dashboard/screens/client/InvoiceList';

export default {
  name: 'BillingConsole',
  data() {
    return {
      showHistory: false,
      invoiceLists: [],
      openPurchaseCredits: false,
      selectedCard: {},
      cardDetails: [],
      calendlyKey: null,
      isLoading: true,
      modalOpen: false,
      showMore: false,
      removedCard: '',
      showLoding: false,
      lastUpdated: Date.now(),
      projectTeam: {},
      showScheduleCallModal: false,
      calendlyLoading: true,
      calendlyLoadTimer: null,
      scheduleCallSource: null,
    };
  },
  components: {
    // ProducerContact,
    Loader,
    PageTitle,
    Modal,
    PaymentMethod,
    PurchaseCredits,
    InvoiceList,
    CreditHistory,
    CreditBank,
    // Copy,
    // OrderAssets,
  },
  computed: {
    ...mapGetters('business', ['business']),
    ...mapGetters('profile', { profile: 'clientProfile' }),
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('dashboard', ['project']),
    getVertical() {
      return _.get(this.business, 'businessType.name');
    },
    businessId() {
      return _.get(this.userDetails, 'currentBusinessId');
    },
    Invoicelist() {
      if (this.showMore) {
        return this.invoiceLists;
      }
      return this.invoiceLists.slice(0, 5);
    },
    getClientUpdateWebFlowLink() {
      const businessType = this.business.businessType
        && this.business.businessType.name;

      if (businessType === 'E-Commerce') {
        return process.env.VUE_APP_WEB_FLOW_ECOMMERCE_LINK;
      }
      if (businessType === 'Professional Services') {
        return process.env.VUE_APP_WEB_FLOW_PROFESSIONAL_SERVICES_LINK;
      }
      if (businessType === 'Technology') {
        return process.env.VUE_APP_WEB_FLOW_TECHNOLOGY_LINK;
      }
      return null;
    },
    projects() {
      return this.business.projects && _.get(this.business, 'projects').length;
    },
    contentProducers() {
      return (this.projectTeam.producersTeam || []).map((item) => ({
        ...item.user,
      })).map((user) => ({
        ...user,
        ...user.producerProfile,
      }));
    },
    producerCallInfo() {
      const info = {};
      const business = _.get(this.project, 'business', {});
      const pointOfContact = _.get(business, 'pointOfContact', null);
      const { defaultContact } = this.profile;

      const contactWithCalendly = [...this.contentProducers].find(({ calendly }) => calendly);
      if (contactWithCalendly) {
        info.userId = contactWithCalendly.id;
        info.displayName = `${contactWithCalendly.firstName} ${contactWithCalendly.lastName}`;
        info.email = contactWithCalendly.email;
        info.avatar = contactWithCalendly.profilePic;
        info.callLink = contactWithCalendly.calendly;
      }

      // if there is no cp with calendly link, then use the first cp
      if (!info.callLink && this.contentProducers.length) {
        info.userId = this.contentProducers[0].id;
        info.displayName = `${this.contentProducers[0].firstName} ${this.contentProducers[0].lastName}`;
        info.email = this.contentProducers[0].email;
        info.avatar = this.contentProducers[0].profilePic;
        info.callLink = this.contentProducers[0].calendly;
      }

      // if there is no cp's at all use default contact
      if (this.contentProducers.length === 0 && defaultContact) {
        info.userId = defaultContact.id;
        info.displayName = `${defaultContact.firstName} ${defaultContact.lastName}`;
        info.email = defaultContact.email;
        info.avatar = defaultContact.profilePic;
        info.callLink = defaultContact.calendly;
      }

      // if there is a point of contact
      if (pointOfContact) {
        info.userId = pointOfContact.id;
        info.displayName = `${pointOfContact.firstName} ${pointOfContact.lastName}`;
        info.email = pointOfContact.email;
        info.avatar = pointOfContact.profilePic;
        info.callLink = pointOfContact.calendly;
        info.representive = pointOfContact.role.name === 'producer' ? 'Content Producer' : 'Production Manager';
      }

      if (info.callLink) {
        let link = info.callLink;
        if (link.indexOf('http') === -1) {
          link = `http://${link}`;
        }
        const url = new URL(link);
        info.isValidLink = url.host === 'calendly.com';
      }
      if (Object.keys(info).length === 0) {
        return null;
      }
      return info;
    },
    callScheduled() {
      return _.get(this.userDetails, 'onboardingStatus.schedule');
    },
  },
  methods: {
    ...mapActions('project', ['getProjectTeam']),
    ...mapActions('user', ['updateScheduleACallStatus', 'paymentMethods', 'removePaymentCard', 'cardsPaidList']),
    ...mapActions('profile', ['getProfile']),
    ...mapActions('business', ['getBusiness', 'getReasons', 'getCreditsHistory']),
    purchaseCredits() {
      this.openPurchaseCredits = true;
    },
    openPaymentMethod() {
      this.cardDetails = [];
      this.modalOpen = true;
      this.lastUpdated = Date.now();
    },
    closePaymentCard() {
      this.$router.push({ query: {} });
      this.modalOpen = false;
      this.reloadCardsList();
      this.lastUpdated = Date.now();
    },
    updatePaymentMethod(val) {
      this.selectedCard = _.cloneDeep(_.find(this.cardDetails, ['id', val]));
      this.modalOpen = true;
      this.lastUpdated = Date.now();
    },
    closePurchaseModal() {
      this.openPurchaseCredits = false;
      this.reloadCardsList();
    },
    async reloadCardsList() {
      const result = await this.paymentMethods(this.businessId);
      this.cardDetails = result.paymentMethods;
      const result2 = await this.cardsPaidList(this.businessId);
      this.invoiceLists = result2.invoices;
      await this.getProfile();
      await this.getBusiness(this.businessId);
      await this.getReasons();
      await this.getCreditsHistory({ businessId: this.businessId });
      this.lastUpdated = Date.now();
    },
    async removePaymentMethod(val, index) {
      this.removedCard = index;
      this.showLoding = true;
      const result = await this.removePaymentCard(val.paymentMethodId);
      if (result.success) {
        this.showLoding = false;
        this.selectedCard = {};
        this.reloadCardsList();
      }
      if (result.data.error) {
        this.showLoding = false;
      }
    },
    appendInviteeParams(link) {
      const { firstName, lastName, email } = this.profile || {};
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append('name', `${firstName} ${lastName}`);
      urlSearchParams.append('email', email);
      urlSearchParams.append('timestamp', Date.now());
      return `${link}?${urlSearchParams.toString()}`;
    },
    orderAssets() {
      // this.modalOpen = true;
      this.$router.push('/order_assets');
    },
    appendBusinessParamsToWebFlowLink(link) {
      const {
        name: businessName,
      } = this.business;
      const {
        firstName,
        lastName,
        email,
        number,
      } = (this.profile || {});
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append('business_name', businessName);
      urlSearchParams.append('email', email);
      urlSearchParams.append('phone', number);
      urlSearchParams.append('first_name', firstName);
      urlSearchParams.append('last_name', lastName);
      urlSearchParams.append('customer_id', this.userDetails.id);
      urlSearchParams.append('redirect_url', encodeURIComponent(window.location.href));
      return `${link}?${urlSearchParams.toString()}`;
    },
    subscription(key) {
      return _.get(this.business, `subscriptionBlends[0].${key}`);
    },
    getIcon(type) {
      if (type === -1) {
        return require('@/assets/svg/gray-check.svg');
      }
      return require('@/assets/svg/green-check.svg');
    },
    isExists(key) {
      if (this.subscription('access')) {
        return _.toLower(this.subscription('access')).indexOf(key);
      }
      return -1;
    },
    handleScheduleCall(options = {}) {
      if (options && options.scheduleCallSource) {
        this.scheduleCallSource = options.scheduleCallSource;
      } else {
        this.scheduleCallSource = null;
      }
      if (this.producerCallInfo.callLink) {
        if (this.producerCallInfo.isValidLink) {
          // this.calendlyLoading = true;
          if (!this.calendlyLoadTimer) {
            this.calendlyLoadTimer = setTimeout(() => {
              this.calendlyLoading = false;
            }, 10000);
          }
        } else {
          this.calendlyLoading = false;
        }
        this.toggleScheduleCallModal(true);
        const vueCalendlyRef = this.$refs.vueCalendly;
        if (vueCalendlyRef) {
          this.calendlyLoading = true;
          const rootNode = vueCalendlyRef.$el;
          const iframe = rootNode.querySelector('iframe');
          if (iframe) {
            const url = this.producerCallInfo
              && this.appendInviteeParams(this.producerCallInfo.callLink);
            this.calendlyKey = url;
            iframe.src = url;
          }
        }
      } else {
        const element = document.createElement('a');
        element.setAttribute('target', '_blank');
        element.setAttribute('href', `mailto:${this.producerCallInfo.email}`);
        element.click();
      }
    },
    toggleScheduleCallModal(flag) {
      this.showScheduleCallModal = flag;
    },
    async handleCalendlyEvent(e) {
      const isCalendlyEvent = e.data.event && e.data.event.indexOf('calendly') === 0;
      if (isCalendlyEvent) {
        const { data } = e;
        if (data.event === 'calendly.event_type_viewed') {
          this.calendlyLoading = false;
        }
        if (data.event === 'calendly.event_scheduled') {
          const requestPayload = {
            eventURI: data.payload.event.uri.split('/').pop(),
            contactType: 'billing_support',
            inviteeId: this.producerCallInfo.userId,
          };
          await this.updateScheduleACallStatus(requestPayload);
        }
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    this.reloadCardsList();
    await this.getProfile();
    await this.getBusiness(_.get(this.userDetails, 'currentBusinessId'));
    await this.getReasons();
    await this.getCreditsHistory({ businessId: _.get(this.userDetails, 'currentBusinessId') });
    // const result = await this.paymentMethods(this.userDetails.currentBusinessId);
    // this.cardDetails = result.paymentMethods.data;
    // const businessIds = _.get(this.userDetails, 'currentBusinessId');
    // if (businessIds) {
    //   await this.getBusiness(businessIds);
    //   if (this.business && this.business.projects && this.business.projects.length) {
    //     this.projectTeam = await this.getProjectTeam(this.business.projects[0].id);
    //   }
    // }
    this.isLoading = false;
    window.addEventListener('message', this.handleCalendlyEvent);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleCalendlyEvent);
    if (this.calendlyLoadTimer) {
      clearTimeout(this.calendlyLoadTimer);
    }
  },
};
</script>
<style lang="scss" scoped>
.card-headings {
  font-size: 12px;
}
  .billing-width {
    .payment-heading {
      font-size: 24px;
      font-weight: 500;
      color: #929292;
      border-bottom: 1px solid #d8d8d8;
    }
    @media (max-width: 600px) {
      .buttons {
        margin-left: 200px;
      }
    }
  }
  .credit-bank {
    .credits-heading {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
    .plan {
    p {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #999999;
      margin-bottom: 0px !important;
    }
    .count {
      font-weight: 600;
      font-size: 24px;
      color: #262626;
    }
    .history {
      cursor: pointer;
      width: max-content;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
    .plan-type {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #262626;
      .plan-desc {
        padding-left: 10px;
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #96A9A9
      }
    }
    .billingLink {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $secondary1;
      cursor: pointer;
    }
  }
  }
  .bs_calendly_wrapper{
    position: relative;
  }
  .bs_calendly_loader {
  width: 100%;
  height: 100%;
  position: absolute;
}
.bs_calendly_fallback {
  padding: 1rem 2rem;
  font-weight: 500;
  color: rgb(45, 44, 44);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.billing-container {
    padding: 40px;
    padding-top: 10px;
    .addLocation {
    cursor: pointer;
    font-family: $fontFamily1;
    font-weight: bold;
    font-size: 14px;
    color: $secondary1;
  }
  .locationIcon {
    font-weight: bold;
    font-size: 18px;
    color: $secondary1;
  }
    .add-payment-btn {
      color: $primary1;
      cursor: pointer;
    }
    .close-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      background-color: #9d9d9d;
    }
    .billing-title {
        border-bottom: 1px solid #B3B3B3;
        padding-bottom: 14px;
        margin-bottom: 38px;
    }
    ::v-deep {
            .v-expansion-panels .v-expansion-panel-header {
              padding: 15px 20px 20px;
              align-items: baseline;
              .v-expansion-panel-header__icon .v-icon {
                font-size: 2rem !important;
                color: $charcoalBlack !important;
              }
            }
        }
    .plans {
        box-shadow: 0px 2px 4px rgba(195, 195, 195, 0.25);
        border-radius: 10px;
        .opacity .sub {
            color: #c2c2c2cc !important;
        }
        .cred-div {
            margin-bottom: 15px;
            .check-icon {
                width: 21px;
                height: 16px;
                padding-right: 10px;
            }
        }
        .v-card {
            background: #FFFFFF;
            min-width: 321px;
            font-family: $fontFamily1;
            .v-card__text {
                box-shadow: 0px 2px 4px rgba(195, 195, 195, 0.25);
                border-radius: 10px !important;
            }
        }
        .current-plan {
            font-family: $fontFamily1;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 29px;
            text-decoration-line: underline;
            color: $charcoalBlack;
        }
    }
    .note {
            margin-top: 20px;
            font-family: $fontFamily1;
            font-style: italic;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            color: #676767;
        }
        .text {
            padding-bottom: 20px;
        }
}

@media (max-width: 598px) {
  .billing-container {
    padding: 15px;
  }
}

.purchase-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem !important;
  padding: 1.5rem !important;
  .heading {
    margin-bottom: 0.5rem;
  }
  .action-button-wrapper{
    .v-btn {
      width: fit-content;
      ::v-deep .v-btn__content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .purchase-btn {
    flex-direction: column;
    align-items: flex-start;
    .purchase-btn-info {
      margin-bottom: 1rem;
    }
  }
}
</style>
